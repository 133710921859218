import React from 'react'
import styled from 'styled-components';
import A from "../files/HompageImages/4.JPG";
import B from "../files/HompageImages/5.JPG";
//import D from "../files/HompageImages/2.JPG";
import {motion} from "framer-motion"
import Carousel from 'react-bootstrap/Carousel';
import {pageAnimation} from "../animation"
import News from './addOns/News';
import Sponsors from './addOns/Sponsors';
import Footer from "./Footer"

const Home = () => {
  return (
    <Container 
        variants={pageAnimation} 
        initial="hidden" 
        animate="show"
        exit="exit"
        style={{background:"#fff"}}
       >
    <Carousel fade id="carousel">
    <Carousel.Item>
        <img
          className="d-block w-100 cover"
          src={A}
          alt="First slide"
        />

        <Carousel.Caption>
          <h3>Пъстрина RUN 2025</h3>
          <p>НДЕЛЯ, 02 ФЕВРУАРИ 2025Г. ОТ 11:00 Ч.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 cover"
          src={B}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Очин дол RUN 2025</h3>
          <p>НЕДЕЛЯ, 12 ЯНУАРИ 2025 Г. ОТ 11:00 Ч.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
     {/*<News />*/}  
       <Sponsors />
  
       <Footer />
    </Container>
  )
}

const Container = styled(motion.div)`
   overflow: 'auto'
`
export default Home